<template>
  <div class="QRcode" v-fixed>
    <!-- 头部 -->
    <div class="topbar">
      <img @click="$router.push('/UserInfo')" :src='require("@/assets/imgs/back.png")' style="width: .8rem;height: .6rem;    margin-left: .64rem;"/>
      <div class="head-title">{{ $t("帳號憑證") }}</div>
    </div>

    <div class="content">
      <div class="content-t mb-10">
        <p class="ma-0 pa-0">G18{{ $t("帳號憑證") }}</p>
        <h1 class="ma-0 pa-0">{{ $t("請截圖保存您的帳號憑證") }}</h1>
      </div>
      <div class="content-m">
        <vue-qr v-if="text" :text="text" :margin="0" />
      </div>
      <div class="hint">
        <h3>{{ $t("為什麽要保存帳號憑證") }}？</h3>
        <p>
          {{
            $t("如果帳號丟失,可在忘記密碼-帳號憑證找回上傳憑證或掃描憑證找回")
          }}
        </p>
      </div>

      <div v-show="!token" class="login-btn mt-3">
        <v-btn
          height="50"
          block
          class="rounded-lg mt-1 btn1"
          bottom
          @click="Login"
        >
          {{ $t("登錄帳號") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n/index";
import VueQr from "vue-qr";
export default {
  components: {
    VueQr,
  },
  data() {
    return {
      text: "none",
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.account = this.$route.params.account;
    this.key = this.$route.params.key;
    this.text = JSON.stringify({ account: this.account, key: this.key });
  },
  methods: {
    // 点数查询
    Login() {
      this.$router.push("/login");
    },
  },
  directives: {
    fixed: {
      // inserted 被绑定元素插入父节点时调用
      inserted() {
        let scrollTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        document.body.style.cssText +=
          "position:fixed;width:100%;top:-" + scrollTop + "px;";
      },
      // unbind 指令与元素解绑时调用
      unbind() {
        let body = document.body;
        body.style.position = "";
        let top = body.style.top;
        document.body.scrollTop = document.documentElement.scrollTop =
          -parseInt(top);
        body.style.top = "";
      },
    },
  },
};
</script>

<style  lang="scss" scoped>
.QRcode {
  padding-top: 46px;
  background-color: #F0F6F9;
  .topbar {
    display: flex;
    align-items: center;
    background-color: #F0F6F9;
    text-align: left;
    height: 46px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    .v-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 24px;
    }
    .head-title {
      position: absolute;
      line-height: 1;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      left: 50%;
      font-size: 18px;
      color: #485573;
    }
  }

  .content {
    height: calc(100vh - 48px);
    padding: 50px 24px 0;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    .content-t {
      padding: 20px 10px 10px;
      width: 100%;
      background-color: #f74177;
      border-radius: 5px;
      p {
        font-size: 18px;
        line-height: 20px;
        color: #ffffff;
      }
      h1 {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        color: #ffffff;
      }
    }
    .content-m {
      img {
        display: block;
        width: 100%;
      }
    }
    .hint {
      h3 {
        font-size: 16px;
        color: #000;
        line-height: 28px;
      }
      p {
        font-size: 14px;
        color: #666666;
        line-height: 18px;
      }
    }
    .login-btn {
      width: 100%;
      .v-btn {
        background: linear-gradient(91.69deg, #f84077 16.39%, #f95e93 139.39%);
        box-shadow: 0px 15px 20px rgba(245, 86, 136, 0.2);
        color: #fff;
        font-size: 16px;
      }
    }
  }
}
</style>